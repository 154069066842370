import React, { useEffect, useState } from 'react';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Verifica se o usuário já aceitou os cookies
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (!cookiesAccepted) {
      setShowBanner(true);
    }

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShowBanner(false);
        localStorage.setItem('cookiesAccepted', 'true'); // Salva a aceitação no localStorage
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!showBanner) return null;

  return (
    <div style={styles.banner}>
      <p style={styles.text}>
        Utilizamos cookies para melhorar a sua experiência. Ao continuar navegando no site, você concorda com o uso de cookies e com a nossa <a href="/privacidade.pdf" style={styles.link} target="_blank" rel="noopener noreferrer">Política de Privacidade</a>.
      </p>
    </div>
  );
};

const styles = {
  banner: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    padding: '15px',
    backgroundColor: '#000',
    color: '#fff',
    textAlign: 'center',
    zIndex: '1000',
  },
  text: {
    margin: 0,
  },
  link: {
    color: '#F2CB84',
    textDecoration: 'underline',
  }
};

export default CookieBanner;
