import { useEffect, useState } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBCard } from "mdb-react-ui-kit";
import { getTokenFromLocalStorage } from "../controllers/authController";
import '../index.css';
import DashboardAdd from "../components/dashboard/dashboard_add";
import DashboardEdit from "../components/dashboard/dashboard_edit";
import DashboardRemove from "../components/dashboard/dashboard_remove";
import DashboardBanners from "../components/dashboard/dashboard_banners";
import DashboardLeads from "../components/dashboard/dashboard_leads";
import DashboardPromo from "../components/dashboard/dashboard_promo";
import DashboardEditCategories from "../components/dashboard/dashboard_category"; // Importar o componente de edição de categorias
import { MdOutlineDiscount, MdOutlineCategory } from "react-icons/md"; // Adicionar ícone de categorias
import { IoTrashOutline, IoCreateOutline } from "react-icons/io5";
import { MdOutlineCreate, MdOutlineAnnouncement } from "react-icons/md";
import { TbDiscount } from "react-icons/tb";
import Logo from '../img/Logo-PDV.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../styles/dashboard.css';

function Dashboard() {
    const [view, setView] = useState('dashboard');

    useEffect(() => {
        const token = getTokenFromLocalStorage();

        if (!token) {
            console.error('Token não encontrado. O usuário não está autenticado.');
            alert('Usuário não autorizado.');
            window.location.href = '/dashboard/login';
            return;
        }

        AOS.init();
    }, []);

    return (
        <div>
            <div className="navbarDashboard">
                <MDBRow>
                    <MDBCol className="navbarDashboardCol">
                        <div onClick={() => setView('dashboard_add')}><IoCreateOutline className="navbarIcon" /></div>
                        <div onClick={() => setView('dashboard_remove')}><IoTrashOutline className="navbarIcon" /></div>
                        <div onClick={() => setView('dashboard_edit')}><MdOutlineCreate className="navbarIcon" /></div>
                        <div onClick={() => setView('dashboard_edit_categories')}><MdOutlineCategory className="navbarIcon" /></div> {/* Aba de categorias */}
                        <div onClick={() => setView('dashboard_leads')}><TbDiscount className="navbarIcon" /></div>
                    </MDBCol>
                </MDBRow>
            </div>

            {view === 'dashboard' ? (
                <MDBContainer className="dashboard dashboardMain">
                    <MDBRow className='contentTitle' style={{ marginTop: '5vh', marginLeft: '5rem', marginRight: '5rem' }}>
                        <MDBCol>
                            <h2>Seja bem-vindo ao painel administrativo</h2>
                            <h4 style={{ textTransform: 'none' }}>Aqui você pode gerenciar seus produtos, criando, deletando ou editando os mesmos, selecione abaixo o painel de administração desejado.</h4>
                            <h4 style={{ textTransform: 'none' }}>Você também pode navegar utilizando os ícones na barra de navegação lateral.</h4>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow style={{ marginTop: '0rem' }}>
                        <MDBCol className="d-flex justify-content-center flex-wrap">
                            <MDBCard className="dashboardMainCards" onClick={() => setView('dashboard_add')}>
                                <IoCreateOutline style={{ fontSize: '2rem', color: '#617DF8' }}></IoCreateOutline > Adicionar Produto
                            </MDBCard>
                            <MDBCard className="dashboardMainCards" onClick={() => setView('dashboard_remove')}>
                                <IoTrashOutline style={{ fontSize: '2rem', color: '#617DF8' }}></IoTrashOutline> Remover Produto
                            </MDBCard>
                            <MDBCard className="dashboardMainCards" onClick={() => setView('dashboard_edit')}>
                                <MdOutlineCreate style={{ fontSize: '2rem', color: '#617DF8' }}></MdOutlineCreate> Editar Produto
                            </MDBCard>
                            <MDBCard className="dashboardMainCards" onClick={() => setView('dashboard_edit_categories')}>
                                <MdOutlineCategory style={{ fontSize: '2rem', color: '#617DF8' }}></MdOutlineCategory> Editar Categorias {/* Novo card de categorias */}
                            </MDBCard>
                            <MDBCard className="dashboardMainCards" onClick={() => setView('dashboard_leads')}>
                                <TbDiscount style={{ fontSize: '2rem', color: '#617DF8' }}></TbDiscount> Visualizar Leads
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow fluid style={{ position: 'absolute', bottom: '25px', right: '25px', padding: '0' }}>
                        <img src={Logo} alt='imagem de sobre nós 1' style={{ width: '200px' }} ></img>
                    </MDBRow>
                </MDBContainer>
            ) : view === 'dashboard_add' ? (
                <DashboardAdd />
            ) : view === 'dashboard_remove' ? (
                <DashboardRemove />
            ) : view === 'dashboard_edit' ? (
                <DashboardEdit />
            ) : view === 'dashboard_edit_categories' ? ( // Novo componente para edição de categorias
                <DashboardEditCategories />
            ) : view === 'dashboard_leads' ? (
                <DashboardLeads />
            ) : null}
        </div>
    );
}

export default Dashboard;
