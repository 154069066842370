import React, { useState, useEffect } from 'react';
import { MDBRow, MDBCol, MDBCard } from 'mdb-react-ui-kit';
import { api } from "../controllers/api-controller";

function CategoryCard({ categoria }) {
    if (!categoria) {
        return <p>Categoria não encontrada.</p>;
    }

    return (
        <MDBCard data-aos="fade-up" className="productCard3" style={{ cursor: 'pointer' }}>
            <h4 className='productCardText2'>{categoria.nome}</h4>
            <p>{categoria.descricao}</p>
        </MDBCard>
    );
}

function ListaCategoriasEdit({ onCategorySelect, atualizarLista, notificarAtualizacao }) {
    const [categorias, setCategorias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [categoriasPorPagina] = useState(15); // Quantidade de categorias por página
    const [categoriasPaginadas, setCategoriasPaginadas] = useState([]);

    useEffect(() => {
        carregarCategorias();
    }, [atualizarLista]);

    useEffect(() => {
        const indexOfLastCategory = paginaAtual * categoriasPorPagina;
        const indexOfFirstCategory = indexOfLastCategory - categoriasPorPagina;
        setCategoriasPaginadas(categorias.slice(indexOfFirstCategory, indexOfLastCategory));
    }, [paginaAtual, categorias, categoriasPorPagina]);

    const carregarCategorias = () => {
        setLoading(true);
        fetch(`${api}/categorias/get`) // Buscando todas as categorias
            .then(response => response.json())
            .then(data => {
                setCategorias(data.categorias || []);
                setLoading(false);
            })
            .catch(error => {
                console.error('Erro ao buscar categorias:', error);
                setLoading(false);
            });
    };

    const handleCategoryClick = (categoria) => {
        onCategorySelect(categoria);
    };

    if (loading) {
        return <p>Carregando categorias...</p>;
    }

    const totalPaginas = Math.ceil(categorias.length / categoriasPorPagina);

    return (
        <>
            <MDBRow className='productGrid'> {/* Adiciona a classe productGrid para organizar as categorias */}
                {categoriasPaginadas.length > 0 ? (
                    categoriasPaginadas.map(categoria => (
                        <MDBCol md="4" key={categoria.id} onClick={() => handleCategoryClick(categoria)}>
                            <CategoryCard categoria={categoria} />
                        </MDBCol>
                    ))
                ) : (
                    <p>Nenhuma categoria encontrada.</p>
                )}
            </MDBRow>
            <MDBRow className='d-flex justify-content-center' style={{ width: '99%', marginTop: '25px' }}>
                <div className="pagination-controls" style={{ width: '60%' }}>
                    <button
                        onClick={() => setPaginaAtual(p => Math.max(1, p - 1))}
                        disabled={paginaAtual === 1}
                    >
                        Anterior
                    </button>
                    <span>Página {paginaAtual} de {totalPaginas || 1}</span>
                    <button
                        onClick={() => setPaginaAtual(p => Math.min(p + 1, totalPaginas))}
                        disabled={paginaAtual === totalPaginas}
                    >
                        Próximo
                    </button>
                </div>
            </MDBRow>
        </>
    );
}

export default ListaCategoriasEdit;
