import React from "react";
import Slider from "react-slick";

const ImageCarousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Quantidade padrão de slides (para telas menores)
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024, // Para dispositivos maiores que 1024px (desktops e laptops)
                settings: {
                    slidesToShow: 3, // Exibe 3 slides
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768, // Para dispositivos entre 768px e 1024px (tablets)
                settings: {
                    slidesToShow: 2, // Exibe 2 slides
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 480, // Para dispositivos abaixo de 480px (smartphones)
                settings: {
                    slidesToShow: 1, // Exibe 1 slide
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="carousel-wrapper pb-5">
            <Slider {...settings}>
                <div className="carousel-item-wrapper">
                    <img src="https://fakeimg.pl/300x300" alt="Imagem 1" />
                </div>
                <div className="carousel-item-wrapper">
                    <img src="https://fakeimg.pl/300x300" alt="Imagem 2" />
                </div>
                <div className="carousel-item-wrapper">
                    <img src="https://fakeimg.pl/300x300" alt="Imagem 3" />
                </div>
            </Slider>
        </div>
    );
};

export default ImageCarousel;
