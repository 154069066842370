import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import "../../styles/about.css"; // Certifique-se de que o caminho está correto
import Sadia_IMG from '../../img/galery/sadia.jpeg';


const FirstSection = () => {
  return (
    <MDBContainer className="first-section">
      <MDBRow className="align-items-center justify-content-center">
        <MDBCol md="6" className="text-section">
          <h2 className="first-title text-center color-default">Conheça mais sobre nós</h2>
          <p className="first-description  text-center">
            A PDV em Foco transforma ideias em soluções visuais impactantes,
            oferecendo experiências personalizadas que elevam a comunicação de
            cada negócio.
          </p>
        </MDBCol>
        <MDBCol md="8" className="image-section">
          <img src={Sadia_IMG} alt='representação de um projeto feito com Sadia pela PDV em Foco' className="img-fluid about-header-img" />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default FirstSection;
