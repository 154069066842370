import React from 'react';
import '../styles/svgs.css'; // Certifique-se de que este arquivo contém as classes .path1, .path2, etc.

const LogoPdv2 = () => {
    return (
        <svg id="Camada_2" data-name="Camada 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.56 170.89" className="my-svg">
            <g id="Layer_1" data-name="Layer 1">
                <g>
                    <path className="path2" d="m109.61,140.41h0l3.24,6.48,3.63,6.26c-18.58,10.73-39.73,12.91-58.92,7.77-19.19-5.14-36.42-17.61-47.14-36.17l-.12-.24C-.31,105.99-2.47,84.93,2.65,65.83,7.79,46.64,20.26,29.41,38.82,18.69l7.26,12.54c-15.1,8.72-25.24,22.73-29.42,38.35-4.19,15.62-2.42,32.83,6.28,47.92h.01c8.72,15.09,22.73,25.23,38.35,29.42,15.62,4.18,32.82,2.42,47.91-6.29l.4-.2Z" />
                    <path className="path1" d="m104.61,132.42c12.85-7.42,21.48-19.35,25.04-32.63,3.56-13.29,2.05-27.93-5.37-40.78-7.42-12.85-19.35-21.48-32.64-25.04-13.28-3.56-27.93-2.05-40.78,5.37l-5.81-10.06c15.63-9.03,33.44-10.86,49.6-6.53,16.16,4.33,30.67,14.82,39.69,30.45,9.02,15.63,10.86,33.44,6.53,49.6-4.33,16.16-14.82,30.67-30.45,39.69l-5.81-10.06Z" />
                    <path className="path3" d="m104.61,132.42c12.85-7.42,21.48-19.35,25.04-32.63,3.56-13.29,2.05-27.93-5.37-40.78-.14-.23-.27-.47-.41-.7l10.06-5.81c.14.23.27.46.41.7,9.02,15.63,10.86,33.44,6.53,49.6-4.33,16.16-14.82,30.67-30.45,39.69l-5.81-10.06Z" />
                    <path className="path4" d="m106.93,135.61c-13.79,7.96-29.5,9.58-43.76,5.76-14.26-3.82-27.05-13.08-35.02-26.87-7.96-13.79-9.58-29.5-5.76-43.76,3.82-14.26,13.08-27.05,26.87-35.02l8.53,14.78c-9.71,5.61-16.23,14.62-18.92,24.65-2.69,10.04-1.55,21.1,4.06,30.81,5.61,9.71,14.62,16.23,24.65,18.92,10.04,2.69,21.1,1.55,30.81-4.06l8.53,14.78Z" />
                    <path className="path5" d="m29.27.36c-.2-.35-.64-.46-.99-.27-.35.2-.47.64-.27.99l97.83,169.44c.2.34.64.46.99.27.35-.2.47-.64.27-.99L29.27.36Z" />
                    <path className="path6" d="m78.04,85.17l16.64,28.82c15.92-9.19,21.37-29.54,12.18-45.45-9.19-15.91-29.54-21.37-45.45-12.18l16.64,28.82Z" />
                    <path className="path6" d="m.79,128.93c-.35.2-.47.64-.27.99.2.35.64.47.99.27L144.2,47.8c.35-.2.47-.64.27-.99-.2-.35-.64-.47-.99-.27L.79,128.93Z" />
                </g>
            </g>
        </svg>
    );
};

export default LogoPdv2;
