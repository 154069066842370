import React, { useState, useEffect } from "react";
import Slider from "react-slick"; // Importando o componente de Slider do react-slick
import logos from "../img/clients/logos.json"; // Suas imagens de marcas
import { MDBContainer, MDBCol, MDBRow } from "mdb-react-ui-kit";
import "../styles/carousel.css"; // Estilos customizados

const Ferramentas = () => {
  const [loadedImages, setLoadedImages] = useState([]);

  useEffect(() => {
    let tempLoadedImages = Array(logos.length).fill(false);

    logos.forEach((logo, index) => {
      const img = new Image();
      img.src = require(`../img/clients/${logo}`);
      img.onload = () => {
        tempLoadedImages[index] = true;
        setLoadedImages([...tempLoadedImages]); // Atualiza o estado com as imagens carregadas
      };
    });
  }, []);

  // Configurações do Slick Carousel
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true, // Habilita autoload
    autoplaySpeed: 800, // Velocidade de 3 segundos
    lazyLoad: 'ondemand', // Carrega as imagens apenas quando necessário
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };


  return (
    <MDBContainer>
      <MDBRow className="carousel-section justify-content-center align-items-center">
        <MDBCol md={6} className="carousel-text">
          <h2>Grandes Marcas, Grandes Parcerias</h2>
          <p>
            Empresas de renome confiam na PDV em Foco para entregar soluções
            visuais de alta qualidade, sempre com agilidade e precisão. Seja
            para grandes campanhas ou pequenos projetos, estamos prontos para
            superar expectativas.
          </p>
        </MDBCol>
        <MDBCol md={12}>
          <Slider {...settings}>
            {logos.map((logo, index) => (
              <div key={index} className="carousel-item">
                {loadedImages[index] ? (
                  <img
                    src={require(`../img/clients/${logo}`)}
                    alt={`${logo.split(".")[0]} Logo`}
                    className="brand-logo"
                  />
                ) : (
                  <div className="placeholder">Carregando...</div> // Placeholder enquanto a imagem carrega
                )}
              </div>
            ))}
          </Slider>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Ferramentas;
