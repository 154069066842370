import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom"; 
import "../../styles/produto.css";
import { api } from "../../controllers/api-controller";
import { IoChevronForwardSharp } from "react-icons/io5";

const BottomSection = ({ similarProducts }) => {
  return (
    <MDBContainer className="py-5">
      <MDBRow className="justify-content-center align-items-center">
        <MDBCol md={6} className="similar-products">
          <h1>Descubra mais soluções para o seu negócio</h1>
          <p>
            Aproveite a chance de explorar produtos que complementam suas
            necessidades e fazem a diferença!
          </p>
        </MDBCol>
      </MDBRow>
      {similarProducts && similarProducts.length > 0 ? (
        <MDBRow className="justify-content-center align-items-center">
          {similarProducts.slice(0, 4).map((product, index) => (
            <MDBCol md="3" key={index} className="product-col">
              <MDBCard className="produtos-card-ai">
                <MDBCardBody>
                  <MDBCardImage
                    src={`${api}/${product.url_imagem}`} 
                    alt={product.nome}
                    className="product-img img-fluid"
                  />
                  <MDBCardTitle className="produto-title">
                    {product.nome}
                  </MDBCardTitle>
                  <Link to={`/produto/${product.slug}`}>
                    <span className="produtos-btn">
                      Quero Conhecer <IoChevronForwardSharp size={20} />
                    </span>
                  </Link>
                  <span className="product-tags">
                    Tags: {product.tags}.
                  </span>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          ))}
        </MDBRow>
      ) : (
        <MDBRow className="justify-content-center align-items-center">
          <MDBCol md={8}>
            <div className="text-center">
              No momento, não encontramos produtos relacionados, mas fique à
              vontade para explorar outras opções em nosso catálogo.
            </div>
          </MDBCol>
        </MDBRow>
      )}
    </MDBContainer>
  );
};

export default BottomSection;
