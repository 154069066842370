import React from "react";
import MiddleSection from "../components/category/middlesection";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import { MDBContainer } from "mdb-react-ui-kit";

const Product = () => {
  return (
    <MDBContainer fluid className="p-0 category-container">
      <Navbar />
      <MiddleSection />
      <Footer />
    </MDBContainer>
  );
};

export default Product;
