import React, { useEffect, useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBBtn,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { IoChevronForwardSharp } from "react-icons/io5";
import { LuMoveRight } from "react-icons/lu";
import axios from "axios";
import { api } from "../../controllers/api-controller";
import { useNavigate, useParams } from "react-router-dom";
import { Riple } from "react-loading-indicators"; // Importa o loader
import "../../styles/category.css";

const MiddleSection = () => {
  const { categoria_slug } = useParams();
  const [activeCategory, setActiveCategory] = useState("all");
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para controlar o loader
  const [error, setError] = useState(null);
  const [expandedProduct, setExpandedProduct] = useState(null); // Estado para controlar produto expandido

  const navigate = useNavigate();

  useEffect(() => {
    // Função para buscar produtos e extrair categorias
    const fetchProducts = async () => {
      setLoading(true); // Ativa o loader antes de buscar os produtos
      try {
        const response = await axios.get(`${api}/produtos`);
        const produtos = response.data.produtos;

        // Extrair categorias únicas com base nos produtos
        const categoriasUnicas = produtos
          .map((produto) => ({
            nome: produto.categoria_nome,
            slug: produto.categoria_slug,
          }))
          .filter(
            (categoria, index, self) =>
              index === self.findIndex((c) => c.slug === categoria.slug)
          ); // Remove duplicatas pelo slug

        setCategories(categoriasUnicas);
        setProducts(produtos);

        if (
          categoria_slug &&
          categoriasUnicas.some((cat) => cat.slug === categoria_slug)
        ) {
          setActiveCategory(categoria_slug);
        } else {
          setActiveCategory("all");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [categoria_slug]);

  const handleTabClick = (categorySlug) => {
    setActiveCategory(categorySlug);

    if (categorySlug === "all") {
      navigate("/categorias");
    } else {
      navigate(`/categorias/${categorySlug}`);
    }
  };

  const handleProductClick = (product) => {
    if (expandedProduct && expandedProduct.id === product.id) {
      // Se o produto já está expandido, colapsá-lo
      setExpandedProduct(null);
    } else {
      // Expandir o novo produto
      setExpandedProduct(product);
    }
  };

  const handleVariantClick = (product, variant) => {
    // Navega para a página do produto com o estado da variante selecionada
    navigate(`/produto/${product.slug}`, { state: { variant } });
  };


  const filteredProducts =
    activeCategory === "all"
      ? products
      : products.filter((product) => product.categoria_slug === activeCategory);

  if (loading) {
    return (
      <MDBContainer className="text-center py-5">
        <MDBRow className="loader-row justify-content-center align-items-center">
          <MDBCol md={5}>
            <h1 className="text-center">
              Explorando as melhores soluções para seu negócio
            </h1>
            <Riple color="#3150cc" size="medium" text="" textColor="" />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }

  if (error) {
    return <div>Erro: {error}</div>;
  }

  return (
    <MDBContainer className="middle-section py-5">
      <h1 className="section-title text-center">
        O impacto que sua marca precisa
      </h1>
      <h4 className="text-center">
        Nossos produtos são a solução ideal para seu negócio
      </h4>

      {/* Renderizar as abas de categorias */}
      <MDBRow className="mb-4 d-flex justify-content-center">
        <MDBCol md={8}>
          <MDBTabs pills className="mb-3 justify-content-center">
            <MDBTabsItem>
              <MDBTabsLink
                className="produtos-tab-v2"
                onClick={() => handleTabClick("all")}
                active={activeCategory === "all"}
              >
                Todos
              </MDBTabsLink>
            </MDBTabsItem>
            {categories.map((category, index) => (
              <MDBTabsItem key={index}>
                <MDBTabsLink
                  className="produtos-tab-v2"
                  onClick={() => handleTabClick(category.slug)}
                  active={activeCategory === category.slug}
                >
                  {category.nome}
                </MDBTabsLink>
              </MDBTabsItem>
            ))}
          </MDBTabs>
        </MDBCol>
      </MDBRow>

      {/* Renderizar os produtos da categoria ativa */}
      <MDBRow className="d-flex justify-content-center mb-5">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product, index) => (
            <MDBCol md="4" key={index} className="product-col">
              <MDBCard className="produtos-card-ai">
                <MDBCardBody>
                  <MDBCardImage
                    src={`${api}/produtos/${product.url_imagem}`}
                    alt={product.nome}
                    className="product-img img-fluid"
                  />
                  <MDBCardTitle className="produto-title">
                    {product.nome}
                  </MDBCardTitle>
                  <MDBBtn
                    onClick={() => handleProductClick(product)}
                    className="produtos-btn"
                  >
                    Quero Conhecer <IoChevronForwardSharp size={20} />
                  </MDBBtn>
                  <span className="product-tags">Tags: {product.tags}.</span>

                  {expandedProduct && expandedProduct.id === product.id && (
                    <div className="variants-container mt-3">
                      {product.variantes && product.variantes.length > 0 ? (
                        product.variantes.map((variant, vIndex) => (
                          <MDBCard
                            key={vIndex}
                            className="variant-card mb-2"
                          >
                            <MDBCardBody>
                              <MDBRow className="align-items-center">
                                <MDBCol md="12">
                                  <MDBCardImage
                                    src={`${api}/produtos/${variant.url_imagem.split(",")[0]}`}
                                    alt={`Variante ${vIndex + 1}`}
                                    className="variant-img img-fluid"
                                  />
                                </MDBCol>
                                <MDBCol md="12">
                                  <MDBCardTitle className="variant-title">
                                    {variant.nome || `${product.nome} - Variante ${vIndex + 1}`}
                                  </MDBCardTitle>
                                  <MDBBtn
                                    onClick={() => handleVariantClick(product, variant)}
                                    className="produtos-btn mt-2"
                                  >
                                     Abrir Produto
                                  </MDBBtn>
                                </MDBCol>
                              </MDBRow>
                            </MDBCardBody>
                          </MDBCard>
                        ))
                      ) : (
                        <Link to={`/produto/${product.slug}`}>
                          <MDBBtn className="produtos-btn mt-2">
                            Abrir Produto
                          </MDBBtn>
                        </Link>
                      )}
                    </div>
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          ))
        ) : (
          <div>Sem produtos disponíveis para esta categoria.</div>
        )}
      </MDBRow>
    </MDBContainer>
  );
};

export default MiddleSection;
