import { useState, useEffect, useRef } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { api, getTokenFromLocalStorage } from "../../controllers/authController";
import '../../styles/dashboard.css';
import ListaCategoriasEdit from "../category_edit";

function DashboardEditCategories() {
    const [atualizarLista, setAtualizarLista] = useState(false);
    const [categoryId, setCategoryId] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [categoryDescription, setCategoryDescription] = useState('');
    const [selectedFile, setSelectedFile] = useState(null); // Estado para a imagem
    const [imagePreview, setImagePreview] = useState(''); // Pré-visualização da imagem
    const imageToRemoveRef = useRef(null); // Ref para armazenar imagem a ser removida

    // Função para atualizar a lista de categorias
    const handleCategoryUpdate = () => {
        setAtualizarLista(prevState => !prevState);
    };

    // Função para lidar com a seleção da imagem
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            const imageUrl = URL.createObjectURL(file);
            setImagePreview(imageUrl);
        }
    };

    // Função para remover a imagem selecionada
    const handleImageRemove = () => {
        setSelectedFile(null);
        setImagePreview('');
        imageToRemoveRef.current = imagePreview;
    };

    // Função para envio do formulário
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!categoryName) {
            alert('Por favor, preencha o nome da categoria.');
            return;
        }

        const formData = new FormData();
        formData.append('nome', categoryName);
        formData.append('descricao', categoryDescription);

        // Adicionar a nova imagem ao formData, se houver
        if (selectedFile) {
            formData.append('imagem', selectedFile);
        }

        // Remover a imagem, se necessário
        if (imageToRemoveRef.current) {
            formData.append('imagemRemover', imageToRemoveRef.current);
        }

        try {
            const token = getTokenFromLocalStorage();

            if (!token) {
                console.error('Token não encontrado. O usuário não está autenticado.');
                alert('Usuário não autorizado.');
                window.location.href = '/dashboard/login';
                return;
            }

            const response = await fetch(`${api}/categorias/update/${categoryId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}` // Prefixo 'Bearer' adicionado
                },
                body: formData,
            });            

            if (response.ok) {
                alert('Categoria atualizada com sucesso!');
                handleCategoryUpdate();
            } else {
                alert('Não foi possível atualizar a categoria. Tente novamente.');
            }
        } catch (error) {
            console.error('Erro ao atualizar a categoria:', error);
            alert('Erro ao tentar atualizar a categoria.');
        }
    };

    const onCategorySelect = (categoria) => {
        setCategoryId(categoria.id);
        setCategoryName(categoria.nome);
        setCategoryDescription(categoria.descricao);
        setImagePreview(`${api}/${categoria.url_imagem}`); // Carrega a imagem da categoria
    };

    return (
        <div>
            <MDBContainer className="dashboard-content-container">
                <MDBRow className="dashboardRow">
                    <h2>EDIÇÃO DE CATEGORIA</h2>
                    <h6>Selecione abaixo a categoria que deseja editar</h6>
                </MDBRow>
                <MDBRow fluid style={{ padding: '0', width: '100%', margin: '0' }}>
                    <ListaCategoriasEdit onCategorySelect={onCategorySelect} atualizarLista={atualizarLista} notificarAtualizacao={handleCategoryUpdate} />
                </MDBRow>
            </MDBContainer>
            <MDBContainer className="dashboard">
                <MDBRow fluid className="dashboardRow" style={{ padding: '0' }}>
                    <form onSubmit={handleSubmit} className="dashboardForm">
                        <MDBCol className="dashboardCol">
                            <h6>Para atualizar sua categoria, altere as informações abaixo</h6>
                            <MDBRow md={12} className="box-section" style={{ width: '90%' }}>
                                <MDBCol md={12}>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Nome da Categoria</label>
                                        <input
                                            id="categoryName"
                                            type="text"
                                            placeholder="Ex: Comunicação Visual"
                                            value={categoryName}
                                            onChange={(e) => setCategoryName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Descrição da Categoria</label>
                                        <textarea
                                            id="categoryDescription"
                                            rows={5}
                                            value={categoryDescription}
                                            onChange={(e) => setCategoryDescription(e.target.value)}
                                        />
                                    </div>

                                    {/* Exibir imagem selecionada ou carregada */}
                                    <div className="image-preview">
                                        {imagePreview ? (
                                            <div>
                                                <img
                                                    src={imagePreview}
                                                    alt="Pré-visualização"
                                                    style={{ width: "100px", height: "100px", margin: "10px", borderRadius: '15px' }}
                                                />
                                                <MDBBtn className="remove-btn" onClick={handleImageRemove}>Remover Imagem</MDBBtn>
                                            </div>
                                        ) : (
                                            <p>Imagem não disponível</p>
                                        )}
                                    </div>

                                    <div className="mb-4">
                                        <label style={{ padding: '15px' }} htmlFor="categoryImage">Imagem da Categoria</label>
                                        <input
                                            className="upload-btn"
                                            type="file"
                                            id="categoryImage"
                                            onChange={handleFileChange}
                                        />
                                    </div>

                                    <MDBBtn className="confirmBtn" type="submit">CONFIRMAR E ATUALIZAR CATEGORIA</MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </form>
                </MDBRow>
            </MDBContainer>
        </div>
    );
}

export default DashboardEditCategories;
