import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import "react-image-lightbox/style.css"; // Importa o estilo do lightbox
import Lightbox from "react-image-lightbox"; // Importa o componente de Lightbox
import "../styles/gallery.css";
import Footer from "../components/footer";
import Navbar from "../components/navbar";

// Importando todas as imagens da pasta ../img/galery dinamicamente
const importAll = (r) => r.keys().map(r);
const images = importAll(
  require.context("../img/galery", false, /\.(png|jpe?g|svg)$/)
);

// Função para embaralhar o array de imagens (algoritmo de Fisher-Yates)
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const Gallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [shuffledImages, setShuffledImages] = useState([]);

  // Embaralha as imagens ao carregar o componente
  useEffect(() => {
    setShuffledImages(shuffleArray([...images])); // Clona e embaralha as imagens
  }, []);

  return (
    <>
      <Navbar />
      <MDBContainer className="gallery-container">
        <MDBRow className="justify-content-center align-items-center">
          <MDBCol md={6} className="similar-products">
            <h1>Explore Nossos Projetos de Sucesso</h1>
            <p>
              Confira nossa galeria de projetos e inspire-se nas soluções
              visuais que já transformaram negócios. Cada imagem conta uma
              história de inovação e impacto. Seu próximo projeto pode estar
              aqui!
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow className="justify-content-center align-items-center">
          <MDBCol>
            <div className="masonry-grid">
              {shuffledImages.map((src, index) => (
                <div key={index} className="masonry-item">
                  <img
                    src={src}
                    alt={`Gallery Item ${index + 1}`}
                    onClick={() => {
                      setPhotoIndex(index); // Define o índice da imagem
                      setIsOpen(true); // Abre o lightbox
                    }}
                    className="gallery-image"
                  />
                </div>
              ))}
            </div>
            {isOpen && (
              <Lightbox
                mainSrc={shuffledImages[photoIndex]}
                nextSrc={shuffledImages[(photoIndex + 1) % shuffledImages.length]}
                prevSrc={
                  shuffledImages[(photoIndex + shuffledImages.length - 1) % shuffledImages.length]
                }
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex(
                    (photoIndex + shuffledImages.length - 1) % shuffledImages.length
                  )
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % shuffledImages.length)
                }
              />
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Footer />
    </>
  );
};

export default Gallery;
