import { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from "mdb-react-ui-kit";
import { api, getTokenFromLocalStorage } from "../../controllers/authController";
import '../../styles/dashboard.css';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function formatCellphoneNumber(cellphone) {
    // Assuming cellphone is a string of 11 digits (e.g., "12345678901")
    const formattedCellphone = `(${cellphone.slice(0, 2)}) ${cellphone.charAt(2)} ${cellphone.slice(3, 7)}-${cellphone.slice(7)}`;
    return formattedCellphone;
}

function DashboardLeads() {
    const [leads, setLeads] = useState([]);
    const [searchName, setSearchName] = useState('');
    const [searchEmail, setSearchEmail] = useState('');
    const [searchCellphone, setSearchCellphone] = useState('');

    useEffect(() => {
        fetchLeads();
    }, []);

    const fetchLeads = async () => {
        try {
            const response = await fetch(`${api}/leads/get`);
            const data = await response.json();
            setLeads(data.leads);
        } catch (error) {
            console.error('Error fetching leads:', error);
        }
    };

    const filteredLeads = leads.filter((lead) =>
        lead.nome_lead.toLowerCase().includes(searchName.toLowerCase()) &&
        lead.email_lead.toLowerCase().includes(searchEmail.toLowerCase()) &&
        lead.celular_lead.toLowerCase().includes(searchCellphone.toLowerCase())
    );

    return (
        <div>
            <MDBContainer className="dashboard">
                <MDBRow className="dashboardRow d-flex justify-content-center" style={{ textAlign: 'center' }}>
                    <h2>Visualização de Cadastros</h2>
                    <h6>Visualize e localize abaixo os usuários cadastrados no site</h6>
                    <MDBCol md={3} style={{textAlign:'left', marginTop:'2rem'}}>
                        <h3>Pesquisa de Cadastros</h3>
                        <label>Insira informações nos campos abaixo para localizar cadastros especificos:</label>
                        <MDBInput
                            className="dashboard-lead-search-input"
                            label="Procurar por Nome"
                            type="text"
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                        />
                        <MDBInput
                            className="dashboard-lead-search-input"
                            label="Procurar por e-mail"
                            type="text"
                            value={searchEmail}
                            onChange={(e) => setSearchEmail(e.target.value)}
                        />
                        <MDBInput
                            className="dashboard-lead-search-input"
                            label="Procurar por Celular"
                            type="text"
                            value={searchCellphone}
                            onChange={(e) => setSearchCellphone(e.target.value)}
                        />
                    </MDBCol>
                    <MDBCol md={7} className="box-section" style={{ height: '80svh', overflowY: 'scroll', overflowX: 'hidden', borderRadius: '10px', padding:'5px'}}>
                        <MDBRow fluid className="d-flex justify-content-center">
                            {filteredLeads.map((lead) => (
                                <MDBCol fluid className="dashboard-lead-card" md={6} key={lead.id}>
                                    <div style={{padding:'5px', border:'solid 2px #e1e1e1', borderRadius:'15px'}}>
                                        <h3>Nome: {capitalizeFirstLetter(lead.nome_lead)}</h3>
                                        <div style={{}}>Email: {lead.email_lead} </div>
                                        <div style={{}}> Celular: {formatCellphoneNumber(lead.celular_lead)}</div>
                                    </div>
                                </MDBCol>
                            ))}
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
    );
}

export default DashboardLeads;