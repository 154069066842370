import { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { api, getTokenFromLocalStorage } from "../../controllers/authController";
import '../../styles/dashboard.css';

function DashboardAdd() {
    const [productName, setProductName] = useState('');
    const [formatoTamanho, setFormatoTamanho] = useState('');
    const [cores, setCores] = useState('');
    const [papel, setPapel] = useState('');
    const [enobrecimentoAcabamento, setEnobrecimentoAcabamento] = useState('');
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedImagePreviews, setSelectedImagePreviews] = useState([]);
    const [productDescription, setProductDescription] = useState('');
    const [tags, setTags] = useState('');
    const [slug, setSlug] = useState('');
    const [selectedThumbnailIndex, setSelectedThumbnailIndex] = useState(0);
    const [hasVariantes, setHasVariantes] = useState(false);
    const [variantes, setVariantes] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(`${api}/categorias/get`);
                const data = await response.json();
                setCategories(data.categorias || []);
            } catch (error) {
                console.error('Erro ao buscar categorias:', error);
            }
        };
        fetchCategories();
    }, []);

    const handleFileChange = (e) => {
        if (e.target.files) {
            const newFiles = Array.from(e.target.files);
            setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);

            const filePreviews = newFiles.map(file => URL.createObjectURL(file));
            setSelectedImagePreviews(prevPreviews => [...prevPreviews, ...filePreviews]);

            return () => filePreviews.forEach(URL.revokeObjectURL);
        }
    };

    const handleThumbnailSelect = (index) => {
        setSelectedThumbnailIndex(index);
    };

    const handleImageRemove = (indexToRemove) => {
        const updatedPreviews = selectedImagePreviews.filter((_, index) => index !== indexToRemove);
        setSelectedImagePreviews(updatedPreviews);

        const updatedFiles = selectedFiles.filter((_, index) => index !== indexToRemove);
        setSelectedFiles(updatedFiles);
    };

    const handleAddVariante = () => {
        setVariantes([...variantes, { nome: '', formato_tamanho: '', cores: '', papel: '', enobrecimento_acabamento: '', imagens: [] }]);
    };

    const handleVarianteChange = (index, field, value) => {
        const updatedVariantes = [...variantes];
        updatedVariantes[index][field] = value;
        setVariantes(updatedVariantes);
    };

    const handleRemoveVariante = (index) => {
        setVariantes(variantes.filter((_, i) => i !== index));
    };

    const handleVarianteFileChange = (index, files) => {
        const updatedVariantes = [...variantes];
        updatedVariantes[index].imagens = files ? Array.from(files) : [];
        setVariantes(updatedVariantes);
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Previne o envio acidental do formulário

        // Verificação de campos obrigatórios
        if (!productName || !productDescription || !tags) {
            alert('Por favor, preencha os campos obrigatórios: Nome, Descrição e Tags.');
            return;
        }

        const formData = new FormData();
        formData.append('nome', productName);
        formData.append('descricao', productDescription);
        formData.append('tags', tags);
        formData.append('slug', slug);
        formData.append('thumbnail_index', selectedThumbnailIndex);

        if (formatoTamanho) formData.append('formato_tamanho', formatoTamanho);
        if (cores) formData.append('cores', cores);
        if (papel) formData.append('papel', papel);
        if (enobrecimentoAcabamento) formData.append('enobrecimento_acabamento', enobrecimentoAcabamento);
        if (category) formData.append('categoria_id', category);

        selectedFiles.forEach((file) => formData.append('produto_imagem', file));

        // Adicionar variantes ao FormData
        if (hasVariantes) {
            variantes.forEach((variante, index) => {
                formData.append(`variantes[${index}][nome]`, variante.nome);
                formData.append(`variantes[${index}][formato_tamanho]`, variante.formato_tamanho);
                formData.append(`variantes[${index}][cores]`, variante.cores);
                formData.append(`variantes[${index}][papel]`, variante.papel);
                formData.append(`variantes[${index}][enobrecimento_acabamento]`, variante.enobrecimento_acabamento);

                (variante.imagens || []).forEach((file) => {
                    formData.append(`variante_imagem_${index}`, file);
                });
            });
        }

        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${api}/produtos/cadastro`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                body: formData,
            });

            if (!response.ok) {
                alert('Não foi possível adicionar o produto. Tente novamente.');
                return;
            }

            alert('Produto adicionado com sucesso!');
            window.location.reload();
        } catch (error) {
            console.error('Erro ao adicionar o produto:', error);
            alert('Erro ao adicionar o produto.');
        }
    };

    return (
        <div>
            <MDBContainer className="dashboard">
                <MDBRow className="dashboardRow">
                    <h2>CADASTRO DE PRODUTO</h2>
                    <form onSubmit={handleSubmit} className="dashboardForm">
                        <MDBCol className="dashboardCol">
                            <h6>Para cadastrar seu produto, insira as informações abaixo</h6>
                            <MDBRow md={12} className="box-section" style={{ width: '90%' }}>
                                <MDBCol md={12}>
                                    {/* Inputs para o produto principal */}
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Categoria</label>
                                        <select
                                            id="category"
                                            value={category}
                                            onChange={(e) => setCategory(e.target.value)}
                                            className="dashboardRadius"
                                        >
                                            <option value="" disabled>Selecione uma categoria</option>
                                            {categories.map(cat => (
                                                <option key={cat.id} value={cat.id}>
                                                    {cat.nome}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Nome do Produto <span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            id="productName"
                                            type="text"
                                            placeholder="Ex: Folheto, Display, Etc"
                                            value={productName}
                                            onChange={(e) => setProductName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Descrição do Produto <span style={{ color: 'red' }}>*</span></label>
                                        <textarea
                                            id="productDescription"
                                            rows={5}
                                            maxLength="2000"
                                            value={productDescription}
                                            onChange={(e) => setProductDescription(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Formato e Tamanho</label>
                                        <input
                                            id="formatoTamanho"
                                            type="text"
                                            placeholder="Ex: 1x1, A4, etc"
                                            value={formatoTamanho}
                                            onChange={(e) => setFormatoTamanho(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Cores</label>
                                        <input
                                            id="cores"
                                            type="text"
                                            placeholder="Ex: CMYK, Pantone, etc"
                                            value={cores}
                                            onChange={(e) => setCores(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Papel</label>
                                        <input
                                            id="papel"
                                            type="text"
                                            placeholder="Ex: Couchê, Offset, etc"
                                            value={papel}
                                            onChange={(e) => setPapel(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Enobrecimento / Acabamento</label>
                                        <input
                                            id="enobrecimentoAcabamento"
                                            type="text"
                                            placeholder="Ex: Verniz, Laminação, etc"
                                            value={enobrecimentoAcabamento}
                                            onChange={(e) => setEnobrecimentoAcabamento(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Tags (Palavras-chave) <span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            id="tags"
                                            type="text"
                                            placeholder="Ex: brinde, personalizado, caneca"
                                            value={tags}
                                            onChange={(e) => setTags(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Slug</label>
                                        <input
                                            id="slug"
                                            type="text"
                                            placeholder="Ex: slug-do-produto"
                                            value={slug}
                                            onChange={(e) => setSlug(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4 dashboardInput">
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                checked={hasVariantes} 
                                                onChange={() => setHasVariantes(!hasVariantes)} 
                                            />
                                            Este produto possui variações?
                                        </label>
                                    </div>
                                    {hasVariantes && (
                                        <div className="variantes-section">
                                            <h5>Adicionar Variações</h5>
                                            {variantes.map((variante, index) => (
                                                <div key={index} className="mb-4 dashboardInput">
                                                    <h6>Variante {index + 1}</h6>
                                                    <input
                                                        type="text"
                                                        placeholder="Nome da Variante"
                                                        value={variante.nome}
                                                        onChange={(e) => handleVarianteChange(index, 'nome', e.target.value)}
                                                    />
                                                    <input
                                                        type="text"
                                                        placeholder="Formato e Tamanho"
                                                        value={variante.formato_tamanho}
                                                        onChange={(e) => handleVarianteChange(index, 'formato_tamanho', e.target.value)}
                                                    />
                                                    <input
                                                        type="text"
                                                        placeholder="Cores"
                                                        value={variante.cores}
                                                        onChange={(e) => handleVarianteChange(index, 'cores', e.target.value)}
                                                    />
                                                    <input
                                                        type="text"
                                                        placeholder="Papel"
                                                        value={variante.papel}
                                                        onChange={(e) => handleVarianteChange(index, 'papel', e.target.value)}
                                                    />
                                                    <input
                                                        type="text"
                                                        placeholder="Enobrecimento / Acabamento"
                                                        value={variante.enobrecimento_acabamento}
                                                        onChange={(e) => handleVarianteChange(index, 'enobrecimento_acabamento', e.target.value)}
                                                    />
                                                    <input
                                                        type="file"
                                                        multiple
                                                        onChange={(e) => handleVarianteFileChange(index, e.target.files)}
                                                    />
                                                    <MDBBtn className="remove-btn" type="button" onClick={() => handleRemoveVariante(index)}>Remover Variante</MDBBtn>
                                                </div>
                                            ))}
                                            <MDBBtn className="add-btn" type="button" onClick={handleAddVariante}>Adicionar Variante</MDBBtn>
                                        </div>
                                    )}
                                </MDBCol>
                                <MDBCol md={12} className="dashboardCol image-view-holder">
                                    <div className="image-preview">
                                        {selectedImagePreviews.map((image, index) => (
                                            <div key={index}>
                                                <div className={`thumbnail-container ${index === selectedThumbnailIndex ? 'selected' : ''}`}>
                                                    {index === selectedThumbnailIndex && <div className="thumbnail-text">Thumbnail</div>}
                                                    <img
                                                        src={image}
                                                        alt="Preview"
                                                        style={{ width: "100px", height: "100px", margin: "10px", borderRadius: '15px' }}
                                                        onClick={() => handleThumbnailSelect(index)}
                                                    />
                                                </div>
                                                <MDBBtn className="remove-btn" type="button" onClick={() => handleImageRemove(index)}>Remover</MDBBtn>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="productImages">Imagens do Produto</label>
                                        <input
                                            className="upload-btn"
                                            type="file"
                                            id="productImages"
                                            multiple
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                    <MDBBtn className="confirmBtn" type="submit">CONFIRMAR E ADICIONAR PRODUTO</MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </form>
                </MDBRow>
            </MDBContainer>
        </div>
    );
}

export default DashboardAdd;
