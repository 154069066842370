import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "../../styles/hero.css";
import { api } from "../../controllers/api-controller";
import { BsSendArrowUp } from "react-icons/bs";
import { Link } from "react-router-dom";
import { IoChevronForwardSharp } from "react-icons/io5";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBBtn,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import { Riple } from "react-loading-indicators";

const Hero = () => {
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [banners, setBanners] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  // Determinar se o dispositivo é mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Definir estado inicial
    handleResize();

    // Adicionar listener para mudanças no tamanho da janela
    window.addEventListener("resize", handleResize);

    // Remover listener ao desmontar o componente
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Buscar banners da API
  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch(`${api}/banners`);
        const data = await response.json();
        setBanners(data.banners || []);
      } catch (error) {
        console.error("Erro ao buscar banners:", error);
      }
    };

    fetchBanners();
  }, []);

  // Buscar categorias da API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${api}/categorias/get`);
        const data = await response.json();
        setCategories(data.categorias || []);
      } catch (error) {
        console.error("Erro ao buscar categorias:", error);
      }
    };

    fetchCategories();
  }, []);

  // Função de busca por produtos
  const handleSearch = async () => {
    if (searchQuery.length < 3) {
      setSearchResults([]);
      return;
    }

    setLoading(true);
    setError(false);
    setSearchResults([]);

    try {
      const response = await fetch(`${api}/produtos/iasearch`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userInput: searchQuery }),
      });
      const data = await response.json();

      if (data.message === "not_found") {
        setError(true);
      } else {
        setSearchResults(data.produtos || data || []);
      }
    } catch (error) {
      console.error("Erro ao buscar produtos:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="d-flex justify-content-center align-items-center flex-column">
      <MDBRow className="justify-content-center align-items-center ai-row">
        <MDBCol md={12}>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagination={false}
            autoplay={{ delay: 3000 }}
            loop={true}
          >
            {banners.map((banner, index) => (
              <SwiperSlide key={index}>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={`${api}/${isMobile ? banner.mobile_image_url : banner.image_url}`}
                    alt={`Banner ${index + 1}`}
                    className="img-fluid swiper-banner"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </MDBCol>

        <MDBCol md={6} className="ai-onboarding-col mt-4">
          <h1>Encontre a solução perfeita para o seu negócio</h1>
          <p>
            É muito fácil: basta descrever o que seu negócio precisa e, com um
            clique no botão, nossa IA irá apresentar as melhores opções para
            você!
          </p>
        </MDBCol>
        <MDBCol
          md={8}
          className="d-flex align-items-center justify-content-center mt-3"
        >
          <div className="ai-inputhero">
            <div className="input-pesquisar">
              <input
                type="text"
                placeholder="Conte um pouco sobre seu negócio ou objetivo de campanha..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                disabled={loading}
              />
              <MDBBtn onClick={() => handleSearch()}>
                <BsSendArrowUp size={25} />
              </MDBBtn>
            </div>
          </div>
        </MDBCol>
        {loading ? (
          <MDBCol md={8} className="pb-3 pt-5 text-center">
            <Riple color="#3150cc" size="medium" text="" textColor="" />
          </MDBCol>
        ) : error ? (
          <MDBCol md={8} className="pb-3 pt-5">
            <p className="text-center ia-error">
              Não encontramos produtos correspondentes à sua pesquisa.
            </p>
          </MDBCol>
        ) : (
          searchResults.length > 0 && (
            <MDBCol md={8} className="pb-3 pt-5">
              <MDBRow className="mt-4">
                {searchResults.map((product, index) => (
                  <MDBCol md="4" key={index} className="product-col">
                    <MDBCard className="produtos-card-ai">
                      <MDBCardBody>
                        <MDBCardImage
                          src={`${api}/produtos/${product.url_imagem}`}
                          alt={product.nome}
                          className="product-img img-fluid"
                        />
                        <MDBCardTitle className="produto-title">
                          {product.nome}
                        </MDBCardTitle>
                        <Link to={`/produto/${product.slug}`}>
                          <span className="produtos-btn">
                            Quero Conhecer <IoChevronForwardSharp size={20} />
                          </span>
                        </Link>
                        <span className="product-tags">
                          Tags: {product.tags}.
                        </span>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                ))}
              </MDBRow>
            </MDBCol>
          )
        )}
      </MDBRow>

      <MDBRow className="cards-horizontal d-flex justify-content-center align-items-center pt-5 mt-5">
        <MDBCol md={5} className="categorias-onboarding-col">
          <h1>Transforme suas ideias em soluções visuais impactantes!</h1>
          <p>
            Na PDV em Foco, nossa missão é dar vida às suas ideias com soluções
            visuais personalizadas e de alta qualidade. Com vasta experiência no
            mercado gráfico, ajudamos empresas de todos os portes a se
            destacarem em suas campanhas de marketing e comunicação por meio de
            materiais promocionais, brindes corporativos e comunicação visual
            que realmente fazem a diferença.
            <br /> <br />
            Descubra como podemos elevar a sua marca e atrair mais resultados
            para o seu negócio!
          </p>
        </MDBCol>
        <MDBCol
          md={6}
          className="justify-content-center align-items-center row mt-4"
        >
          <div className="cards-horizontal row justify-content-center align-items-start">
            {categories.map((category, index) => (
              <MDBCol
                key={index}
                size={4}
                className="mb-4 text-center card-wrapper"
              >
                <a href={`/categorias/${category.slug}`}>
                  <img
                    className="hero-card mb-3"
                    src={`${api}/${category.url_imagem}`}
                    alt={`Imagem de ${category.nome}`}
                  />
                  <span className="categoria-title">{category.nome}</span>
                </a>
              </MDBCol>
            ))}
          </div>
        </MDBCol>
      </MDBRow>
    </section>
  );
};

export default Hero;
