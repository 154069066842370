import { useState, useEffect, useRef } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { api, getTokenFromLocalStorage } from "../../controllers/authController";
import '../../styles/dashboard.css';
import ListaProdutosEdit from "../products_edit";

function DashboardEdit() {
    const [atualizarLista, setAtualizarLista] = useState(false);
    const [productId, setProductId] = useState('');
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productFormatoTamanho, setProductFormatoTamanho] = useState('');
    const [productCores, setProductCores] = useState('');
    const [productPapel, setProductPapel] = useState('');
    const [productEnobrecimento, setProductEnobrecimento] = useState('');
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [productTags, setProductTags] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const imagesToRemoveRef = useRef([]);
    const [selectedThumbnailIndex, setSelectedThumbnailIndex] = useState(0);
    const [hasVariantes, setHasVariantes] = useState(false);
    const [variantes, setVariantes] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    // Carregar categorias ao montar o componente
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(`${api}/categorias/get`);
                const data = await response.json();
                setCategories(data.categorias || []);
            } catch (error) {
                console.error('Erro ao buscar categorias:', error);
            }
        };
        fetchCategories();
    }, []);

    // Atualiza a lista de produtos
    const handleProductUpdate = () => {
        setAtualizarLista(prevState => !prevState);
    };

    // Seleciona o índice do thumbnail
    const handleThumbnailSelect = (index) => {
        setSelectedThumbnailIndex(index);
    };

    // Manipula o upload de imagens
    const handleFileChange = (e) => {
        const files = e.target.files ? Array.from(e.target.files) : [];
        const updatedPreviews = files.map(file => URL.createObjectURL(file));

        setSelectedFiles(prevFiles => [...prevFiles, ...files]);
        setImagePreviews(prevPreviews => [...prevPreviews, ...updatedPreviews]);

        return () => updatedPreviews.forEach(URL.revokeObjectURL);
    };

    // Remove uma imagem
    const handleImageRemove = (indexToRemove) => {
        const updatedPreviews = imagePreviews.filter((_, index) => index !== indexToRemove);
        setImagePreviews(updatedPreviews);

        const updatedFiles = selectedFiles.filter((_, index) => index !== indexToRemove);
        setSelectedFiles(updatedFiles);

        imagesToRemoveRef.current.push(indexToRemove);
    };

    // Adiciona uma nova variante
    const handleAddVariante = () => {
        setVariantes([...variantes, { nome: '', formato_tamanho: '', cores: '', papel: '', enobrecimento_acabamento: '', imagens: [] }]);
    };

    // Manipula a mudança nos campos das variantes
    const handleVarianteChange = (index, field, value) => {
        const updatedVariantes = [...variantes];
        updatedVariantes[index][field] = value;
        setVariantes(updatedVariantes);
    };

    // Manipula o upload de imagens nas variantes
    const handleVarianteFileChange = (index, files) => {
        const updatedVariantes = [...variantes];
        updatedVariantes[index].imagens = files ? Array.from(files) : [];
        setVariantes(updatedVariantes);
    };

    // Remove uma variante
    const handleRemoveVariante = (index) => {
        setVariantes(variantes.filter((_, i) => i !== index));
    };

    // Submete as alterações do produto
    const handleSubmit = async (e) => {
        e.preventDefault(); // Previne o envio acidental do formulário

        if (!productName || !productDescription || !productTags) {
            alert('Por favor, preencha os campos obrigatórios: Nome, Descrição e Tags.');
            return;
        }

        const formData = new FormData();
        formData.append('nome', productName);
        formData.append('descricao', productDescription);
        formData.append('tags', productTags);
        formData.append('thumbnail_index', selectedThumbnailIndex);

        if (productFormatoTamanho) formData.append('formato_tamanho', productFormatoTamanho);
        if (productCores) formData.append('cores', productCores);
        if (productPapel) formData.append('papel', productPapel);
        if (productEnobrecimento) formData.append('enobrecimento_acabamento', productEnobrecimento);
        if (category) formData.append('categoria_id', category);

        // Adiciona imagens removidas
        formData.append('imagensRemover', JSON.stringify(imagesToRemoveRef.current));

        // Adiciona novas imagens selecionadas
        selectedFiles.forEach((file) => {
            formData.append('produto_imagem', file);
        });

        // Adiciona as variantes ao formData
        if (hasVariantes) {
            variantes.forEach((variante, index) => {
                formData.append(`variantes[${index}][nome]`, variante.nome);
                formData.append(`variantes[${index}][formato_tamanho]`, variante.formato_tamanho);
                formData.append(`variantes[${index}][cores]`, variante.cores);
                formData.append(`variantes[${index}][papel]`, variante.papel);
                formData.append(`variantes[${index}][enobrecimento_acabamento]`, variante.enobrecimento_acabamento);

                (variante.imagens || []).forEach((file) => {
                    formData.append(`variante_imagem_${index}`, file);
                });
            });
        }

        try {
            const token = getTokenFromLocalStorage();

            if (!token) {
                console.error('Token não encontrado. O usuário não está autenticado.');
                alert('Usuário não autorizado.');
                window.location.href = '/dashboard/login';
                return;
            }

            const response = await fetch(`${api}/produtos/update/${productId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData,
            });

            if (response.ok) {
                alert('Produto atualizado com sucesso!');
                handleProductUpdate();
                setIsEditing(false);
            } else {
                alert('Não foi possível atualizar o produto. Tente novamente.');
            }
        } catch (error) {
            console.error('Erro ao atualizar o produto:', error);
            alert('Erro ao tentar atualizar o produto.');
        }
    };

    // Seleciona um produto para edição
    const onProductSelect = (produto) => {
        setProductId(produto.id);
        setProductName(produto.nome);
        setProductDescription(produto.descricao);
        setProductFormatoTamanho(produto.formato_tamanho || '');
        setProductCores(produto.cores || '');
        setProductPapel(produto.papel || '');
        setProductEnobrecimento(produto.enobrecimento_acabamento || '');
        setCategory(produto.categoria_id);
        setProductTags(produto.tags || '');

        const imageUrls = produto.url_imagem ? produto.url_imagem.split(',').map(url => `${api}/produtos/${url}`) : [];
        setImagePreviews(imageUrls);

        if (produto.variantes && produto.variantes.length > 0) {
            setHasVariantes(true);
            setVariantes(produto.variantes.map(variante => ({
                nome: variante.nome || '',
                formato_tamanho: variante.formato_tamanho || '',
                cores: variante.cores || '',
                papel: variante.papel || '',
                enobrecimento_acabamento: variante.enobrecimento_acabamento || '',
                imagens: []
            })));
        } else {
            setHasVariantes(false);
            setVariantes([]);
        }

        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    return (
        <div>
            {!isEditing ? (
                <MDBContainer className="dashboard-content-container">
                    <MDBRow className="dashboardRow">
                        <h2>EDIÇÃO DE PRODUTO</h2>
                        <h6>Selecione abaixo o produto que deseja editar</h6>
                    </MDBRow>
                    <MDBRow fluid style={{ padding: '0', width: '100%', margin: '0' }}>
                        <ListaProdutosEdit 
                            onProductSelect={onProductSelect} 
                            atualizarLista={atualizarLista} 
                            notificarAtualizacao={handleProductUpdate} 
                        />
                    </MDBRow>
                </MDBContainer>
            ) : (
                <MDBContainer className="dashboard">
                    <MDBRow fluid className="dashboardRow" style={{ padding: '0' }}>
                        <form onSubmit={handleSubmit} className="dashboardForm">
                            <MDBCol className="dashboardCol">
                                <h6>Para atualizar seu produto, altere as informações abaixo</h6>
                                <MDBRow md={12} className="box-section" style={{ width: '90%' }}>
                                    <MDBCol md={12}>
                                        {/* Inputs para edição do produto principal */}
                                        <div className="mb-4 dashboardInput">
                                            <label className="description-text">Categoria</label>
                                            <select
                                                id="category"
                                                value={category}
                                                onChange={(e) => setCategory(e.target.value)}
                                                className="dashboardRadius"
                                            >
                                                <option value="" disabled>Selecione uma categoria</option>
                                                {categories.map(cat => (
                                                    <option key={cat.id} value={cat.id}>
                                                        {cat.nome}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-4 dashboardInput">
                                            <label className="description-text">Nome do Produto <span style={{ color: 'red' }}>*</span></label>
                                            <input
                                                id="productName"
                                                type="text"
                                                placeholder="Ex: Balanço folha, Balanço Bowl, Etc"
                                                value={productName}
                                                onChange={(e) => setProductName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="mb-4 dashboardInput">
                                            <label className="description-text">Descrição do Produto <span style={{ color: 'red' }}>*</span></label>
                                            <textarea
                                                id="productDescription"
                                                rows={5}
                                                maxLength="2000"
                                                value={productDescription}
                                                onChange={(e) => setProductDescription(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="mb-4 dashboardInput">
                                            <label className="description-text">Formato e Tamanho</label>
                                            <input
                                                id="productFormatoTamanho"
                                                type="text"
                                                placeholder="Ex: 1x1, A4, etc"
                                                value={productFormatoTamanho}
                                                onChange={(e) => setProductFormatoTamanho(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-4 dashboardInput">
                                            <label className="description-text">Cores</label>
                                            <input
                                                id="productCores"
                                                type="text"
                                                placeholder="Ex: CMYK, Pantone, etc"
                                                value={productCores}
                                                onChange={(e) => setProductCores(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-4 dashboardInput">
                                            <label className="description-text">Papel</label>
                                            <input
                                                id="productPapel"
                                                type="text"
                                                placeholder="Ex: Couchê, Offset, etc"
                                                value={productPapel}
                                                onChange={(e) => setProductPapel(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-4 dashboardInput">
                                            <label className="description-text">Enobrecimento / Acabamento</label>
                                            <input
                                                id="productEnobrecimento"
                                                type="text"
                                                placeholder="Ex: Verniz, Laminação, etc"
                                                value={productEnobrecimento}
                                                onChange={(e) => setProductEnobrecimento(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-4 dashboardInput">
                                            <label className="description-text">Tags</label>
                                            <input
                                                id="productTags"
                                                type="text"
                                                placeholder="Ex: brinde, personalizado, caneca"
                                                value={productTags}
                                                onChange={(e) => setProductTags(e.target.value)}
                                                required
                                            />
                                        </div>
                                        {/* Inputs para edição das variantes */}
                                        {hasVariantes && (
                                            <div className="variantes-section">
                                                <h5>Editar Variações</h5>
                                                {variantes.map((variante, index) => (
                                                    <div key={index} className="mb-4 dashboardInput">
                                                        <h6>Variante {index + 1}</h6>
                                                        <label className="description-text">Nome da Variante</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Nome da Variante"
                                                            value={variante.nome}
                                                            onChange={(e) => handleVarianteChange(index, 'nome', e.target.value)}
                                                        />
                                                        <label className="description-text">Formato e Tamanho</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Formato e Tamanho"
                                                            value={variante.formato_tamanho}
                                                            onChange={(e) => handleVarianteChange(index, 'formato_tamanho', e.target.value)}
                                                        />
                                                        <label className="description-text">Cores</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Cores"
                                                            value={variante.cores}
                                                            onChange={(e) => handleVarianteChange(index, 'cores', e.target.value)}
                                                        />
                                                        <label className="description-text">Papel</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Papel"
                                                            value={variante.papel}
                                                            onChange={(e) => handleVarianteChange(index, 'papel', e.target.value)}
                                                        />
                                                        <label className="description-text">Enobrecimento / Acabamento</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enobrecimento / Acabamento"
                                                            value={variante.enobrecimento_acabamento}
                                                            onChange={(e) => handleVarianteChange(index, 'enobrecimento_acabamento', e.target.value)}
                                                        />
                                                        <label className="description-text">Imagens da Variante</label>
                                                        <input
                                                            type="file"
                                                            multiple
                                                            onChange={(e) => handleVarianteFileChange(index, e.target.files)}
                                                        />
                                                        <MDBBtn className="remove-btn" onClick={() => handleRemoveVariante(index)}>Remover Variante</MDBBtn>
                                                    </div>
                                                ))}
                                                <MDBBtn className="add-btn" type="button" onClick={handleAddVariante}>Adicionar Variante</MDBBtn>
                                            </div>
                                        )}
                                    </MDBCol>
                                    <MDBCol md={12} className="dashboardCol image-view-holder" style={{ width: '100%' }}>
                                        <div className="image-preview">
                                            {imagePreviews.map((image, index) => (
                                                <div key={index}>
                                                    <div className={`thumbnail-container ${index === selectedThumbnailIndex ? 'selected' : ''}`}>
                                                        {index === selectedThumbnailIndex && <div className="thumbnail-text">Thumbnail</div>}
                                                        <img
                                                            src={image}
                                                            alt="Preview"
                                                            style={{ width: "100px", height: "100px", margin: "10px", borderRadius: '15px' }}
                                                            onClick={() => handleThumbnailSelect(index)}
                                                        />
                                                    </div>
                                                    <MDBBtn className="remove-btn" onClick={() => handleImageRemove(index)}>Remover</MDBBtn>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="mb-4">
                                            <label style={{ padding: '15px' }} htmlFor="productImages">Imagens do Produto</label>
                                            <input
                                                className="upload-btn"
                                                type="file"
                                                id="productImages"
                                                multiple
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <MDBBtn className="confirmBtn" type="submit">CONFIRMAR E ATUALIZAR PRODUTO</MDBBtn>
                                        <MDBBtn className="cancelBtn" type="button" onClick={handleCancelEdit}>CANCELAR</MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </form>
                    </MDBRow>
                </MDBContainer>
            )}
        </div>
    );
}

export default DashboardEdit;
