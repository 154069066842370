import React, { useState, useEffect } from 'react';
import { MDBRow, MDBCol, MDBCard } from 'mdb-react-ui-kit';
import { api } from "../controllers/api-controller";

function ProductCard({ produto }) {
    if (!produto) {
        return <p>Produto não encontrado.</p>;
    }

    // Verifica se o produto possui `url_imagem` e `thumbnail_index`
    const thumbnailUrl = produto.url_imagem
        ? produto.url_imagem.split(',')[produto.thumbnail_index] || produto.url_imagem.split(',')[0] // Fallback para a primeira imagem
        : '';

    return (
        <MDBCard data-aos="fade-up" className="productCard3" style={{ cursor: 'pointer' }}>
            {thumbnailUrl ? (
                <img src={`${api}/produtos/${thumbnailUrl}`} style={{ zIndex: '0' }} alt={produto.nome} />
            ) : (
                <p>Imagem não disponível</p>
            )}
            <h4 className='productCardText2'>{produto.nome} - {produto.formato_tamanho}</h4>
        </MDBCard>
    );
}

function ListaProdutosDelete({ onProductSelect, atualizarLista, notificarAtualizacao }) {
    const [produtos, setProdutos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [produtosPorPagina] = useState(15); // Quantidade de produtos por página
    const [produtosPaginados, setProdutosPaginados] = useState([]);

    useEffect(() => {
        carregarProdutos();
    }, []);

    useEffect(() => {
        // Paginação no front-end
        const indexOfLastProduct = paginaAtual * produtosPorPagina;
        const indexOfFirstProduct = indexOfLastProduct - produtosPorPagina;
        setProdutosPaginados(produtos.slice(indexOfFirstProduct, indexOfLastProduct));
    }, [paginaAtual, produtos, produtosPorPagina]);

    const carregarProdutos = () => {
        setLoading(true);
        fetch(`${api}/produtos`) // Buscando todos os produtos
            .then(response => response.json())
            .then(data => {
                setProdutos(data.produtos || []);
                setLoading(false);
            })
            .catch(error => {
                console.error('Erro ao buscar produtos:', error);
                setLoading(false);
            });
    };

    const handleProductClick = (produto) => {
        onProductSelect(produto);
    };

    if (loading) {
        return <p>Carregando produtos...</p>;
    }

    const totalPaginas = Math.ceil(produtos.length / produtosPorPagina);

    return (
        <>
            <MDBRow className='productGrid'> {/* Adiciona a classe productGrid para organizar os produtos */}
                {produtosPaginados.length > 0 ? (
                    produtosPaginados.map(produto => (
                        <MDBCol md="4" key={produto.id} onClick={() => onProductSelect(produto)}>
                            <ProductCard produto={produto} />
                        </MDBCol>
                    ))
                ) : (
                    <p>Nenhum produto encontrado.</p>
                )}
            </MDBRow>
            <MDBRow className='d-flex justify-content-center' style={{ width: '99%', marginTop: '25px' }}>
                <div className="pagination-controls" style={{ width: '60%' }}>
                    <button
                        onClick={() => setPaginaAtual(p => Math.max(1, p - 1))}
                        disabled={paginaAtual === 1}
                    >
                        Anterior
                    </button>
                    <span>Página {paginaAtual} de {totalPaginas || 1}</span>
                    <button
                        onClick={() => setPaginaAtual(p => Math.min(p + 1, totalPaginas))}
                        disabled={paginaAtual === totalPaginas}
                    >
                        Próximo
                    </button>
                </div>
            </MDBRow>
        </>
    );
}

export default ListaProdutosDelete;
