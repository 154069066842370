import React, { useEffect, useState } from 'react';
import TopSection from '../components/product/topsection';
import BottomSection from '../components/product/bottomsection';
import { MDBContainer } from 'mdb-react-ui-kit';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import axios from 'axios'; // Importando Axios
import { api } from "../controllers/api-controller";
import { useParams, useLocation } from 'react-router-dom';
import '../styles/produto.css';

const Product = () => {
    const { slug } = useParams(); // Obtém o slug da URL
    const location = useLocation();
    const [product, setProduct] = useState(null);
    const [similarProducts, setSimilarProducts] = useState([]); // Estado para produtos semelhantes
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedVariant, setSelectedVariant] = useState(null); // Estado para a variante selecionada

    useEffect(() => {
        // Função para buscar dados do produto e todos os produtos pela API
        const fetchProductAndSimilarProducts = async () => {
            try {
                // Busca o produto atual
                const productResponse = await axios.get(`${api}/produto/${slug}`);
                const currentProduct = productResponse.data;
                setProduct(currentProduct);
                console.log('Produto atual:', currentProduct);

                // Verifica se há uma variante selecionada na navegação
                const state = location.state;
                if (state && state.variant) {
                    setSelectedVariant(state.variant);
                }

                // Verifica se o produto atual tem um categoria_id válido
                if (!currentProduct || !currentProduct.produto || !currentProduct.produto.categoria_id) {
                    throw new Error('Produto sem categoria_id');
                }

                // Busca todos os produtos
                const allProductsResponse = await axios.get(`${api}/produtos`);
                const allProducts = allProductsResponse.data.produtos; // Verifica o campo correto
                console.log('Todos os produtos:', allProducts);

                // Filtra os produtos semelhantes pela categoria_id do produto atual
                const filteredSimilarProducts = allProducts.filter(
                    (prod) => prod.categoria_id === currentProduct.produto.categoria_id && prod.slug !== slug
                );
                setSimilarProducts(filteredSimilarProducts);
                console.log('Produtos semelhantes:', filteredSimilarProducts);

            } catch (err) {
                console.error('Erro ao buscar dados:', err.message);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProductAndSimilarProducts();
    }, [slug, location]); // Chama a função quando o slug ou a localização mudar

    if (loading) {
        return <div>Carregando...</div>;
    }

    if (error) {
        return <div>Erro: {error}</div>;
    }

    const produtoData = selectedVariant
    ? {
        ...product.produto,
        ...selectedVariant,
        nome: selectedVariant.nome || `${product.produto.nome} - Variante`,
        url_imagem: selectedVariant.url_imagem || product.produto.url_imagem,
        descricao:
          selectedVariant.descricao ||
          product.produto.descricao ||
          "Descrição não disponível.",
      }
    : product.produto;

    return (
        <div>
            <Navbar />
            <MDBContainer fluid className='p-0 m-0 product-container'>
                {produtoData ? (
                    <>
                        <TopSection produto={produtoData} />
                        <BottomSection similarProducts={similarProducts} />
                    </>
                ) : (
                    <div>Produto não encontrado</div>
                )}
            </MDBContainer>
            <Footer />
        </div>
    );
};

export default Product;
