import { api } from "../controllers/api-controller";

function getTokenFromLocalStorage() {
    try {
        const token = localStorage.getItem('authToken');
        if (token) {
            return JSON.parse(token);
        } else {
            console.log('No token found');
            return null;
        }
    } catch (error) {
        console.error('Error retrieving token:', error);
        return null;
    }
}

// Função de login que salva o token no localStorage
async function login(username, password) {
    try {
        let response = await fetch(api + "/auth", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username, // Ajuste para corresponder à chave esperada pelo servidor
                password
            })
        });

        const data = await response.json();

        if (!response.ok) {
            return data;
        }

        // Salvar o token no localStorage
        localStorage.setItem('authToken', JSON.stringify(data.token)); // Ajuste para salvar somente o token

        return data;
    } catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const ContentType = {
    JSON: 'application/json',
};

export { getTokenFromLocalStorage, login, api };