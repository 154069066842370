import { useState } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard } from "mdb-react-ui-kit";
import { api, getTokenFromLocalStorage } from "../../controllers/authController";
import '../../styles/dashboard.css';
import ListaProdutosDelete from "../products_remove";

function DashboardRemove() {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [atualizarLista, setAtualizarLista] = useState(false);

    const notificarAtualizacaoLista = () => {
        setAtualizarLista(atual => !atual); // Alterna o estado para forçar a atualização
    };

    const handleProductSelect = (produto) => {
        setSelectedProduct(produto);
    };

    const deletarProdutoSelecionado = () => {
        if (selectedProduct && selectedProduct.id) {
            // Confirmação antes de deletar o produto
            const confirmarDelecao = window.confirm(`Tem certeza que deseja remover o produto: ${selectedProduct.nome}?`);

            if (!confirmarDelecao) {
                return; // Se o usuário cancelar, não executa a deleção
            }

            const token = getTokenFromLocalStorage();

            if (!token) {
                console.error('Token não encontrado. O usuário não está autenticado.');
                alert('Usuário não autorizado.');
                window.location.href = '/dashboard/login';
                return;
            }

            fetch(`${api}/produtos/delete/${selectedProduct.id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `${token}`
                },
            })
                .then(response => {
                    if (response.ok) {
                        alert('Produto removido com sucesso.');
                        notificarAtualizacaoLista();
                    } else {
                        alert('Falha ao remover o produto.');
                    }
                })
                .catch(error => {
                    console.error('Erro ao deletar produto:', error);
                    alert('Erro ao tentar remover o produto.');
                });
        } else {
            alert('Nenhum produto selecionado.');
        }
    };

    return (
        <div>
            <MDBContainer className="dashboard-content-container">
                <MDBRow className="dashboardRow">
                    <h2>REMOÇÃO DE PRODUTO</h2>
                    <h6>Selecione abaixo o produto para remover</h6>
                </MDBRow>
                <MDBRow fluid style={{ padding: '0', width: '100%', margin: '0' }}>
                    <ListaProdutosDelete onProductSelect={handleProductSelect} atualizarLista={atualizarLista} notificarAtualizacao={notificarAtualizacaoLista} />
                </MDBRow>
            </MDBContainer>
            <MDBContainer className="dashboard-content-container">
                <MDBRow style={{ width: '100%', margin: '0', padding: '0' }}>
                    <MDBCol>
                        <MDBCard className="dashboardRemoveCard">
                            <MDBRow style={{ display: 'flex', alignItems: 'center' }}>
                                <h2>Produto Selecionado</h2>
                                {selectedProduct && (
                                    <>
                                        <MDBCol md={4} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img
                                                src={selectedProduct.url_imagem
                                                    ? `${api}/produtos/${selectedProduct.url_imagem.split(',')[selectedProduct.thumbnail_index]}`
                                                    : `${api}/produtos/imgs/placeholder.png`}
                                                alt={selectedProduct.nome}
                                                style={{ maxWidth: '100%', borderRadius: '10px' }}
                                            />
                                        </MDBCol>
                                        <MDBCol className="selected-remove-product" style={{ marginLeft: '2%' }}>
                                            <p>Nome: {selectedProduct.nome}</p>
                                            <p>Descrição: {selectedProduct.descricao}</p>
                                            <p>Formato e Tamanho: {selectedProduct.formato_tamanho}</p>
                                            <p>Cores: {selectedProduct.cores}</p>
                                            <p>Papel: {selectedProduct.papel}</p>
                                            <p>Enobrecimento: {selectedProduct.enobrecimento_acabamento}</p>
                                            <p>Categoria: {selectedProduct.categoria_id}</p>
                                            <p>Tags: {selectedProduct.tags}</p>
                                        </MDBCol>
                                    </>
                                )}
                            </MDBRow>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="dashboardRow centered-button">
                    <MDBBtn style={{ padding: '1rem' }} className="confirmBtn" type="button" onClick={deletarProdutoSelecionado}>
                        Deseja Remover o Produto Selecionado?
                    </MDBBtn>
                </MDBRow>
            </MDBContainer>
        </div>
    );
}

export default DashboardRemove;
